@import url('https://fonts.googleapis.com/css?family=Cabin&display=swap');
html {
  font-family: 'Cabin', sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;

  font-size: 1em;

  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}

@media (prefers-color-scheme: light) {
  body {
    background-color: white;
    color: black;
  }
}

@media (prefers-color-scheme: dark) {
  body {
    background-color: #121212;
    color: white;
  }
}

input {
  width: 24rem;
  font-size: 3rem;
  text-align: center;
}

.btn {
  margin: 0.5em;

  width: 12rem;
  text-decoration: none;
  display: inline-block;
  padding: 0.5em 2em;
  border-radius: 6px;
  background: none;
  font-weight: 500;
  transition: all 0.25s linear;
  user-select: none;
  outline: none;
}

svg {
  width: 15%;
}

#app {
  text-align: center;
  margin-top: 2rem;
}

#getButtons {
  display: block;
}

.red {
  color: red;
  min-height: 1.5em;
}
